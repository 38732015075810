import React, { Suspense, lazy } from 'react'
import { Router, Route, Switch } from 'react-router'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader'
import CoreLayout from 'layouts/CoreLayout/CoreLayout'
import { asyncComponent } from './utils'
import ProtectedRoute from './ProtectedRoute'
import { checkInProgressStatus } from './hooks'
import InitialRemoteConnectionContent from 'components/DeviceRemoteConnectivity/InitialRemoteConnectionContent'
import RouteChangeTracker from './../routes/RouteChangeTracker'

const DashboardView = asyncComponent('MainView')
const DeviceDetailsView = lazy(() => import('views/DeviceDetailsView'))
const DevicesListView = lazy(() => import('views/DevicesListView'))
const DeviceNotificationStatusView = lazy(() => import('views/DeviceNotificationStatusView'))
const OrganizationDetailsView = lazy(() => import('views/OrganizationDetailsView'))
const UserManagementView = lazy(() => import('views/UserManagementView'))
const UserProfileView = lazy(() => import('views/UserProfileView'))
const DeviceCertificateDashboardView = lazy(() => import('views/DeviceCertificateDashboardView'))
const AddRoleView = lazy(() => import('views/AddRoleView'))
const OrgUsersView = lazy(() => import('views/OrgUsersView'))
const DeviceRegistryView = lazy(() => import('views/DeviceRegistryView'))
const CardListView = lazy(() => import('views/CardListView'))
const ContactHotlineDashboardView = lazy(() => import('views/ContactHotlineDashboardView'))
const DeviceSoftwareUpdateView = lazy(() => import('views/DeviceSoftwareUpdateView'))
const DeviceSoftwareUploadView = lazy(() => import('views/DeviceSoftwareUploadView'))
const DeviceSoftwareValidateView = lazy(() => import('views/DeviceSoftwareValidateView'))
const FileManagementView = lazy(() => import('views/FileManagementView'))
const LogoutContainer = lazy(() => import('containers/LogoutContainer/LogoutContainer'))
const TermsPrivacyView = lazy(() => import('views/TermsPrivacyView'))
const ManageNewsFeedView = lazy(() => import('views/ManageNewsFeedView'))
const DeviceTypesView = lazy(() => import('views/DeviceTypesView'))
const EditCardView = asyncComponent('EditCardView')
const CreateCardView = asyncComponent('CardsCreationView')
const CustomerDevicesView = lazy(() => import('views/CustomerDevicesView'))
const SignupView = asyncComponent('SignupView')
const RatingAndImprovementsView = lazy(() => import('views/RatingAndImprovementsView'))
const FeedbackDefaultersView = lazy(() => import('views/FeedbackDefaultersView'))
const PartnerPeripheralView = lazy(() => import('views/PartnerPeripheralView'))
const RemoteSessionFeedbackView = lazy(() => import('views/RemoteSessionFeedbackView'))
const ResetPasswordView = lazy(() => import('views/ResetPasswordView'))
const ChangePasswordView = lazy(() => import('views/ChangePasswordView'))
const DeviceProfileView = lazy(() => import('views/DeviceProfileView'))
const NewsCategoryManagementView = lazy(() => import('views/NewsCategoryManagementView'))
const ServiceContractManagementView = lazy(() => import('views/ServiceContractManagementView'))
const ContractDetailView = lazy(()=> import('views/ContractDetailView'))
const HowToView = lazy(() => import('views/HowToView'))
const DataAnalysisView = lazy(() => import('views/DataAnalysisView'))
const SoftwareUpgradeView = lazy(() => import('views/SoftwareUpgradeView'))
const RemoteControlAnalysisView = lazy(() => import('views/RemoteControlAnalysisView'))
const IotConnectionAnalysisView = lazy(() => import('views/IotConnectionAnalysisView'))

export const PATHS = {
  HOME: '/',
  LOGIN: '/login',
  LOGIN_SSO: '/ssologin',
  LOGOUT: '/logout',
  DASHBOARD: '/dashboard',
  DEVICE_LIST: '/devices',
  DEVICE_DETAILS_PATH: '/details',
  USER_REGISTRATION: '/register',
  DEVICE_DETAILS: '/devices/:deviceId/details',
  DEVICES_DETAILS_LAB_WATER: '/:deviceId/details',
  USER_MANAGEMENT: '/users',
  USER_PROFILE: '/profile',
  RESET_PASSWORD: '/resetpassword',
  CHANGE_PASSWORD: '/changepassword',
  ORG_USERS: '/organizations/:orgId/users',
  DEVICE_REGISTRY: '/devices/registry',
  DEVICE_PROFILE: '/device/profile/:deviceId',
  CARDS: '/cards',
  EDIT_CARD: '/cards/edit/:cardId',
  EDIT_CARD_LINK: '/cards/edit',
  CARDS_CREATION: '/cards/create',
  ORGANIZATION_DETAILS: '/organizations',
  ADD_ROLE: '/roles/add',
  NOTIFICATION_STATUS: '/devices/:deviceId/notifications',
  CONTACT_HOTLINE_DASHBOARD: '/managecontacthotline',
  DEVICE_SOFTWARE_UPDATE: '/devicesoftware/update',
  DEVICE_SOFTWARE_UPLOAD: '/devicesoftware/upload',
  DEVICE_SOFTWARE_VALIDATE: '/devicesoftware/validate',
  GLOBAL_FILE_MANAGEMENT: '/files',
  CERTIFICATES_STATUS: '/certificatesstatus',
  TERMS_CONDITION: '/terms',
  PRIVACY_POLICY: '/policy',
  MANAGE_NEWS_FEED: '/newsfeed',
  DEVICE_TYPES: '/devices/devicetype',
  USER_PROFILE_LINK: '/users/profile',
  USER_PROFILE_DETAILS: '/users/profile/:userId',
  CUSTOMER_DEVICES: '/users/:userId/customerdevices',
  SIGNUP: '/signup',
  RATING_IMPROVEMENT: '/userexperience',
  USEREXP_FEEDBACK: '/userexperience',
  FEEDBACK_DEFAULTERS: '/feedbackdefaulters',
  REMOTE: '/remote',
  REMOTE_INFO: '/remoteinfo',
  REMOTE_SESSION_FEED_BACK: '/remoteSession',
  PARTNER_PERIPHERAL_DEVICE_MANAGEMENT: '/devices/PartnerPeripheral',
  NEWS_CATEGORY_MANAGEMENT: '/newscategory',
  SERVICE_CONTRACT_MANAGEMENT: '/servicecontract',
  CONTRACT_DETAILS: '/servicecontract/:serviceContractId',
  HOW_TO: '/howto',
  DATA_ANALYSIS: '/dataanalysis',
  KPI_SOFTWARE_UPGRADE: '/kpiSoftwareUpgrade',
  KPI_REMOTE_CONTROL_ANALYSIS: '/kpiRemoteControlAnalysis',
  KPI_IOT_CONNECTION_ANALYSIS: '/kpiIotConnectionAnalysis'
}

const Routes = ({ store, history }) => {
  return (
    <CoreLayout>
      <Router history={history}>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path={PATHS.LOGIN} component={asyncComponent('LoginView')} />
            <Route path={PATHS.LOGIN_SSO} component={asyncComponent('LoginSsoView')} />
            <Route path={PATHS.REMOTE} component={InitialRemoteConnectionContent} />
            <Route path={PATHS.RESET_PASSWORD} component={ResetPasswordView} />
            <Route path={PATHS.CHANGE_PASSWORD} onEnter={checkInProgressStatus(store)} component={ChangePasswordView} />
            <Route path={PATHS.REMOTE_INFO} component={asyncComponent('RemoteConnectionInfoView')} />
            <Route exact path={PATHS.REMOTE_SESSION_FEED_BACK} component={() => <RemoteSessionFeedbackView breadcrumbName='REMOTE_SESSION_FEEDBACK_HEADER' />} />
            <Route exact path={PATHS.HOME} component={asyncComponent('LandingScreenView')} />
            <Route exact path={PATHS.USER_REGISTRATION} component={asyncComponent('UserRegistrationView')} />
            <Route path={PATHS.LOGOUT} component={() => <LogoutContainer history={history} />} />
            <Route exact path={PATHS.DASHBOARD} store={store} component={() => <ProtectedRoute store={store}><DashboardView breadcrumbName='MAIN_MENU_HOME_BUTTON' /></ProtectedRoute>} />
            <Route exact path={PATHS.USER_PROFILE} store={store} component={() => <ProtectedRoute store={store}><UserProfileView isSelfUser breadcrumbName='USER_PROFILE' /></ProtectedRoute>} />
            <Route exact path={PATHS.USER_PROFILE_DETAILS} store={store} component={() => <ProtectedRoute store={store}><UserProfileView breadcrumbName='USER_PROFILE_DETAILS' /></ProtectedRoute>} />
            <Route exact path={PATHS.DEVICE_DETAILS} component={() => <ProtectedRoute store={store}><DeviceDetailsView /></ProtectedRoute>} />
            <Route exact path={PATHS.DEVICES_DETAILS_LAB_WATER} component={() => <ProtectedRoute store={store}><DeviceDetailsView /></ProtectedRoute>} />
            <Route exact path={PATHS.DEVICE_LIST} component={() => <ProtectedRoute store={store}><DevicesListView breadcrumbName='MAIN_MENU_DEVICE_LIST' /></ProtectedRoute>} />
            <Route exact path={PATHS.NOTIFICATION_STATUS} component={() => <ProtectedRoute store={store}><DeviceNotificationStatusView breadcrumbName='MAIN_MENU_NOTIFICATION_STATUS' /></ProtectedRoute>} />
            <Route exact path={PATHS.ADD_ROLE} component={() => <ProtectedRoute store={store}><AddRoleView breadcrumbName='ADD_ROLE' /></ProtectedRoute>} />
            <Route exact path={PATHS.CUSTOMER_DEVICES} component={() => <ProtectedRoute store={store}><CustomerDevicesView breadcrumbName='USER_DEVICES' /></ProtectedRoute>} />
            <Route exact path={PATHS.SIGNUP} component={SignupView} />
            <Route exact path={PATHS.RATING_IMPROVEMENT} component={() => <ProtectedRoute store={store}><RatingAndImprovementsView breadcrumbName='USER_EXPERIENCE_FEEDBACK_IMPROVEMENT' /></ProtectedRoute>} />
            <Route exact path={PATHS.FEEDBACK_DEFAULTERS} component={() => <ProtectedRoute store={store}><FeedbackDefaultersView breadcrumbName='FEEDBACK_DEFAULTERS' /></ProtectedRoute>} />
            <Route exact path={PATHS.CONTRACT_DETAILS} store={store} component={() => <ProtectedRoute store={store}><ContractDetailView breadcrumbName='CONTRACT_DETAILS' /></ProtectedRoute>} />
            <ProtectedRoute store={store}>
              <Route exact path={PATHS.ORGANIZATION_DETAILS} component={() => <OrganizationDetailsView breadcrumbName='ORGANIZATION_DETAILS' />} />
              <Route exact path={PATHS.USER_MANAGEMENT} component={() => <UserManagementView breadcrumbName='MAIN_MENU_USERS' />} />
              <Route exact path={PATHS.DEVICE_REGISTRY} component={() => <DeviceRegistryView breadcrumbName='MAIN_MENU_DEVICE_REGISTRY_BUTTON' />} />
              <Route exact path={PATHS.DEVICE_PROFILE} component={() => <DeviceProfileView breadcrumbName='DEVICE_PROFILE_TITLE' />} />
              <Route exact path={PATHS.CARDS} component={() => <CardListView breadcrumbName='CARDS' />} />
              <Route exact path={PATHS.ORG_USERS} component={() => <OrgUsersView breadcrumbName='ORG_USERS' />} />
              <Route exact path={PATHS.CONTACT_HOTLINE_DASHBOARD} component={() => <ContactHotlineDashboardView breadcrumbName='CONTACT_HOTLINE_DASHBOARD' />} />
              <Route exact path={PATHS.DEVICE_SOFTWARE_UPDATE} component={() => <DeviceSoftwareUpdateView breadcrumbName='DEVICES_SOFTWARE_UPDATES' />} />
              <Route exact path={PATHS.DEVICE_SOFTWARE_UPLOAD} component={() => <DeviceSoftwareUploadView breadcrumbName='DEVICES_SOFTWARE_UPLOAD' />} />
              <Route exact path={PATHS.DEVICE_SOFTWARE_VALIDATE} component={() => <DeviceSoftwareValidateView breadcrumbName='DEVICES_SOFTWARE_VALIDATE' />} />
              <Route exact path={PATHS.GLOBAL_FILE_MANAGEMENT} component={() => <FileManagementView breadcrumbName='GLOBAL_FILE_MANAGEMENT' />} />
              <Route exact path={PATHS.CERTIFICATES_STATUS} component={() => <DeviceCertificateDashboardView breadcrumbName='CERTIFICATES_STATUS' />} />
              <Route exact path={PATHS.TERMS_CONDITION} component={() => <TermsPrivacyView breadcrumbName='TERMS_SERVICE_AGREEMENT' path={PATHS.TERMS_CONDITION} />} />
              <Route exact path={PATHS.PRIVACY_POLICY} component={() => <TermsPrivacyView breadcrumbName='PRIVACY_POLICY' path={PATHS.PRIVACY_POLICY} />} />
              <Route exact path={PATHS.MANAGE_NEWS_FEED} component={() => <ManageNewsFeedView breadcrumbName='MANAGE_NEWS_FEED' />} />
              <Route exact path={PATHS.NEWS_CATEGORY_MANAGEMENT} component={() => <NewsCategoryManagementView breadcrumbName='NEWS_CATEGORY_MANAGEMENT' />} />
              <Route exact path={PATHS.SERVICE_CONTRACT_MANAGEMENT} component={() => <ServiceContractManagementView breadcrumbName='SERVICE_CONTRACT_MANAGEMENT' />} />
              <Route exact path={PATHS.DEVICE_TYPES} component={() => <DeviceTypesView breadcrumbName='MANAGE_DEVICE_TYPE_DASHBOARD_TITLE' />} />
              <Route exact path={PATHS.EDIT_CARD} component={() => <EditCardView breadcrumbName='EDIT_CARD' />} />
              <Route exact path={PATHS.CARDS_CREATION} component={() => <CreateCardView breadcrumbName='CARDS_CREATION' />} />
              <Route exact path={PATHS.PARTNER_PERIPHERAL_DEVICE_MANAGEMENT} component={() => <PartnerPeripheralView breadcrumbName='PARTNER_PERIPHERAL_DEVICE_MANAGEMENT' />} />
              <Route exact path={PATHS.HOW_TO} component={() => <HowToView breadcrumbName='HOW_TO' />} />
              <Route exact path={PATHS.DATA_ANALYSIS} component={() => <DataAnalysisView breadcrumbName='DATA_ANALYSIS' />} />
              <Route exact path={PATHS.KPI_SOFTWARE_UPGRADE} component={() => <SoftwareUpgradeView breadcrumbName='KPI_SOFTWARE_UPGRADE' />} />
              <Route exact path={PATHS.KPI_REMOTE_CONTROL_ANALYSIS} component={() => <RemoteControlAnalysisView breadcrumbName='KPI_REMOTE_CONTROL_ANALYSIS' />} />
              <Route exact path={PATHS.KPI_IOT_CONNECTION_ANALYSIS} component={() => <IotConnectionAnalysisView breadcrumbName='KPI_KPI_IOT_CONNECTION_ANALYSIS' />} />
              {/*<Route path='*' component={asyncComponent('PageNotFoundView')} />*/}
            </ProtectedRoute>
          </Switch>
          <RouteChangeTracker />
        </Suspense>
      </Router>
    </CoreLayout>
  )
}
Routes.propTypes = {
  history: PropTypes.object,
  store: PropTypes.object
}
export default Routes
