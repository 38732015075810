import { createSelector } from 'reselect'
import { fromJS, List } from 'immutable'
import * as dataUtils from 'utils/dataUtils'
import { batch } from 'react-redux'
import safeFetch from 'store/utils/safeFetch'
import {
  selectors as pageSelectors,
  actions as pageActions
} from 'store/modules/Pagination'
import {
  actions as alarmsActions
} from 'store/modules/GlobalAlertsAndAlarms'
import {
  actions as customerTechActions
} from 'store/modules/CustomerTech'
import {
  actions as deviceAssignmentActions
} from 'store/modules/CustomerDevices'
import {
  actions as customerQualityActions
} from 'store/modules/CustomerQuality'
import {
  actions as fileManagementActions
} from 'store/modules/FileManagement'
import { has } from 'lodash'
import {
  actions as newsCategoryActions
} from 'store/modules/NewsCategory'
import { actions as serviceContractActions } from 'store/modules/ServiceContract'
// Constants
export const constants = {
  FETCH_DEVICE_SOFTWARE_UPDATE_SUCCESS: 'listing/FETCH_DEVICE_SOFTWARE_UPDATE_SUCCESS',
  FETCH: 'listing/FETCH',
  FETCH_MODAL: 'listing/FETCH_MODAL',
  FETCH_SUCCESS: 'listing/FETCH',
  RECEIVE_LIST: 'listing/RECEIVE_LIST',
  FETCH_FAILURE: 'listing/FETCH_FAILURE',
  SET_SEARCH_TERM: 'listing/SET_SEARCH_TERM',
  ADD_FILTER_CATEGORY: 'listing/ADD_FILTER_CATEGORY',
  ADD_FILTER_CATEGORY_VALUES: 'listing/ADD_FILTER_CATEGORY_VALUES',
  ADD_FILTERS: 'listing/ADD_FILTERS',
  SELECTED_FILTER: 'listing/SELECTED_FILTER',
  SORT: 'listing/SORT',
  REDUCE_TOTAL_PAGE: 'listing/REDUCE_TOTAL_PAGE',
  ADD_TOTAL_PAGE: 'listing/ADD_TOTAL_PAGE',
  DELETE_ITEM_SUCCESS: 'listing/DELETE_ITEM_SUCCESS',
  DELETE_ITEM_FAILURE: 'listing/DELETE_ITEM_FAILURE',
  ADD_ITEM_SUCCESS: 'listing/ADD_ITEM_SUCCESS',
  ADD_ITEM_FAILURE: 'listing/ADD_ITEM_FAILURE',
  RESET_ADD_ITEM_SUCCESS: 'listing/RESET_ADD_ITEM_SUCCESS',
  RESET_DELETE_ITEM_SUCCESS: 'listing/RESET_DELETE_ITEM_SUCCESS',
  RESET_SORT: 'listing/RESET_SORT',
  SET_DEFAULT_VALUES: 'listing/SET_DEFAULT_VALUES',
  SELECTED_QUERY_PARAM: 'listing/SELECTED_QUERY_PARAM',
  SET_SEARCH_BY: 'listing/SET_SEARCH_BY',
  RESET_LISTING: 'listing/RESET_LISTING',
  SELECTED_API_ARGS: 'listing/SELECTED_API_ARGS',
  FETCHING_BLOCKED: 'listing/FETCHING_BLOCKED',
  RESET_DATA: 'listing/RESET_DATA',
  RESET_FETCH_LIST_SUCCESS: 'listing/RESET_FETCH_LIST_SUCCESS',
  SORTING_HEADERS: 'listing/SORTING_HEADERS',
  APPLY_FILTERS: 'listing/APPLY_FILTERS'
}

const PAGE_LIMIT = 10

// Action Creators
export const actions = {
  updateDeviceSoftwareStatus({ data, payload }) {
    return { type: constants.FETCH_DEVICE_SOFTWARE_UPDATE_SUCCESS, updatedStatus: data, payload: payload }
  },
  getFetchQuery(getState, paginationName, listingName, apiFunctionName) {
    const state = getState()
    const filterBy = selectors.appliedFilterValues(state, listingName).toJS()
    const prevSortObj = selectors.prevSortObj(state, listingName).toJS()
    const sortBy = prevSortObj && prevSortObj[listingName]?.sort
      ? prevSortObj[listingName].sort.sortColumn
      : selectors.sortColumn(state, listingName)
    const orderBy = prevSortObj && (prevSortObj[listingName]?.sort
      ? prevSortObj[listingName].sort.sortDirection
      : selectors.sortDirection(state, listingName))
      ? 'asc' : 'desc'
    const selectedQueryParams = selectors.selectedQueryParams(state, listingName)
    const args = selectors.selectedApiArgs(state, listingName)
    const isFilterRequired = selectors.isFilterLoaded(state, listingName)
    let query = {
      filterBy: filterBy,
      sortBy: sortBy,
      orderBy: orderBy,
      searchBy: getSearchTerm(state, listingName),
      ...getPageDetails(state, paginationName)
    }
    if (isFilterRequired && (apiFunctionName === 'fetchAlertsAndAlarms' || apiFunctionName === 'fetchMaintenanceAlerts'
      || apiFunctionName === 'fetchDeviceAssignments' || apiFunctionName === 'fetchDeviceTypeFiles' || apiFunctionName === 'getServiceContracts')) {
      query.isFilterDataRequired = isFilterRequired
    }
    if (selectedQueryParams && Object.keys(selectedQueryParams).length > 0) {
      query = {
        ...query,
        ...selectedQueryParams
      }
    }
    return args ? { query, ...args } : query
  },
  loadList(apiFunctionName, paginationName, isHandheldDevice, listingName, blockFetch, tableHeaders, data) {
    if(data?.length){
      return (dispatch, getState) => {
        const header = new Headers()
        dispatch({ type: constants.RECEIVE_LIST, data, header, tableHeaders, isHandheldDevice, listingName, apiFunctionName })
      }
    }
    if (blockFetch) {
      return {
        type: constants.FETCHING_BLOCKED
      }
    }
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH, listingName }),
      preventMultipleRequest: true,
      apiFunction: apiFunctionName,
      args: (getState) => actions.getFetchQuery(getState, paginationName, listingName, apiFunctionName),
      onSuccess: ({ data, headers }) => {
        return (dispatch, getState) => {
          const isFilter = selectors.isFilterLoaded(getState(), listingName)
          batch(async () => {
            if (data?.filter) {
              if (apiFunctionName === 'fetchAlertsAndAlarms') {
                if (listingName === 'globalAlertsAndAlarms') {
                  await dispatch(alarmsActions.fetchFilterTypes(data?.filter, getState))
                } else if (listingName === 'CustomerTech') {
                  await dispatch(customerTechActions.getTechFilters(data?.filter))
                } else if (listingName === 'customerQualityAlerts') {
                  await dispatch(customerQualityActions.getFilters(data?.filter))
                }
              }
              if (apiFunctionName === 'fetchDeviceTypeFiles') {
                await dispatch(fileManagementActions.fetchDocumentTypes(data?.filter))
              }
              if (apiFunctionName === 'getNewsCategories') {
                await dispatch(newsCategoryActions.getNewsCategoryFilters(data?.filter))
              }
              if (apiFunctionName === 'getServiceContracts') {
                await dispatch(serviceContractActions.fetchServiceContractFilters(data?.filter))
              }
            }
            if (has(data, 'filter')) {
              if (apiFunctionName === 'fetchDeviceAssignments') {
                await dispatch(deviceAssignmentActions.fetchFilterCategories(data?.filter))
              }
            }
            if (isFilter && apiFunctionName === 'fetchMaintenanceAlerts' && listingName === 'CustomerMaintenance') {
              await dispatch(customerTechActions.getMaintenanceFilters(data?.filter))
            }
            if (!tableHeaders) {
              tableHeaders = selectors.tableHeaders(getState(), listingName)
            }
            // Added a flag for for disabling/enabling the cancel icons in device s/w update
            if (apiFunctionName === 'fetchDeviceSoftwareUpdateList') {
              const modifiedData = data.map((item) => (
                {
                  ...item,
                  disableCancel: null
                }
              ))
              dispatch({ type: constants.RECEIVE_LIST, data: modifiedData, headers, tableHeaders, isHandheldDevice, listingName, apiFunctionName })
            } else {
              dispatch({ type: constants.RECEIVE_LIST, data, headers, tableHeaders, isHandheldDevice, listingName, apiFunctionName })
            }
          })
        }
      },
      onFailure: (error) => {
        return async (dispatch, getState) => {
          if (apiFunctionName === 'fetchAlertsAndAlarms' && error) {
            if (listingName === 'globalAlarmAlerts') {
              await dispatch(alarmsActions.fetchFilterTypesLoaderDisable())
            } else if (listingName === 'customerQuality') {
              await dispatch(customerQualityActions.getFiltersLoaderDisable())
            }
          }
          dispatch({ type: constants.FETCH_FAILURE, error, listingName, tableHeaders })
        }
      }
    })
  },
  applyFilter(listingName) {
    return (dispatch) => {
      dispatch(pageActions.setCurrentPage(1, listingName))
      dispatch({ type: constants.APPLY_FILTERS, listingName })
    }
  },
  applySort(sortColumn, listingName) {
    return { type: constants.SORT, sortColumn, listingName }
  },
  setSearchTerm(searchTerm, listingName) {
    return { type: constants.SET_SEARCH_TERM, searchTerm, listingName }
  },
  addfilterCatogory(filterType, selections, listingName, isDefaultFilter) {
    return (dispatch, getState) => {
      dispatch({ type: constants.SELECTED_FILTER, selections, listingName })
      isDefaultFilter && dispatch({ type: constants.APPLY_FILTERS, listingName })
    }
  },
  addQueryParams(selections, listingName) {
    return { type: constants.SELECTED_QUERY_PARAM, selections, listingName }
  },
  setSearchBy(searchBy, listingName) {
    return {
      type: constants.SET_SEARCH_BY,
      listingName,
      searchBy
    }
  },
  addApiArgs(args, listingName) {
    return { type: constants.SELECTED_API_ARGS, args, listingName }
  },
  /**
    NOTE:
      The current Implementation only clears searchBy value.
      Since resetSort clears data & filters. which is called in
      generic listing container unmount.

    @param {string} listingName
    @return {object}
  */
  resetListing(listingName) {
    return { type: constants.RESET_LISTING, listingName }
  },
  addfilterCatogoryValues(categoryValue, isChecked, listingName) {
    return async (dispatch, getState) => {
      dispatch({ type: constants.FETCH, listingName })
      const userFilters = selectors.filters(getState(), listingName).toJS()
      const filters = userFilters.map((filter) => {
        const categoryValues = filter.categoryValues
        const modifiedCategoryValues = categoryValues.map((item) => {
          return item.name === categoryValue
            ? { ...item, checked: isChecked }
            : { ...item }
        })
        return { ...filter, categoryValues: modifiedCategoryValues }
      })
      dispatch({ type: constants.ADD_FILTERS, filters, listingName })
      dispatch({ type: constants.ADD_FILTER_CATEGORY_VALUES, categoryValue })
    }
  },
  loadFilters(filterCategories, listingName) {
    return async (dispatch, getState) => {
      const filters = filterCategories?.map((filter) => {
        const categoryValues = filter.categoryValues
        const modifiedCategoryValues = categoryValues.map((item) => {
          return { checked: false, ...item }
        })
        return { ...filter, categoryValues: modifiedCategoryValues }
      })
      dispatch({ type: constants.ADD_FILTERS, filters, listingName })
    }
  },
  refreshPage(apiFunctionName, paginationName, listingName) {
    const handlePageWithPagination = async (dispatch, getState) => {
      const totalEle = selectors.totalElements(getState(), listingName)
      let page = await pageSelectors.pagination(getState()).getIn([paginationName, 'currentPage'])
      const totalPages = await selectors.totalPages(getState(), listingName)
      if (totalEle === 0 && page >= 1) {
        page = page - 1
        dispatch({ type: constants.REDUCE_TOTAL_PAGE, totalPages, listingName })
      }
      if (totalEle >= 10) {
        page = page === totalPages ? totalPages : totalPages + 1
        dispatch({ type: constants.ADD_TOTAL_PAGE, totalPages, listingName })
      }
      if (totalEle === 0 || totalEle >= 10) {
        dispatch(pageActions.setCurrentPage(page, paginationName))
        dispatch(actions.loadList(apiFunctionName, paginationName, null, listingName))
      }
    }

    return dataUtils.isString(paginationName)
      ? handlePageWithPagination
      : actions.loadList(apiFunctionName, paginationName, null, listingName)
  },
  removeItem(apiFunctionName, args, listingName, additionalVal) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_MODAL, listingName }),
      apiFunction: apiFunctionName,
      args: args,
      onSuccess: ({ data }, getState) => {
        const totalEle = selectors.totalElements(getState(), listingName) - 1
        return {
          type: constants.DELETE_ITEM_SUCCESS,
          documentId: args.documentId,
          status: 'success',
          docName: args.docName,
          totalEle,
          listingName,
          additionalVal
        }
      },
      onFailure: (error) => ({ type: constants.DELETE_ITEM_FAILURE, error: error.errorCode || error.status, listingName })
    })
  },
  uploadFile(args, listingName) {
    return safeFetch({
      onFetch: () => ({ type: constants.FETCH_MODAL, listingName }),
      apiFunction: 'uploadFile',
      args: args,
      onSuccess: ({ data }, getState) => {
        const totalEle = selectors.totalElements(getState(), listingName) + 1
        return { type: constants.ADD_ITEM_SUCCESS, item: data, totalEle, listingName }
      },
      onFailure: (error) => ({ type: constants.ADD_ITEM_FAILURE, error: error.message, listingName }),
      throwError: true
    })
  },
  resetAddItemStatus(listingName) {
    return { type: constants.RESET_ADD_ITEM_SUCCESS, listingName }
  },
  resetDeleteItemStatus(listingName) {
    return { type: constants.RESET_DELETE_ITEM_SUCCESS, listingName }
  },
  resetSort(listingName) {
    return { type: constants.RESET_SORT, listingName }
  },
  setInitialValues(values, listingName) {
    const { defaultSortDirection, defaultSort } = values

    return {
      type: constants.SET_DEFAULT_VALUES,
      data: dataUtils.rectifyObject({
        defaultSortDirection,
        defaultSort
      }),
      listingName
    }
  },
  resetFetchStatus(listingName) {
    return { type: constants.RESET_FETCH_LIST_SUCCESS, listingName }
  },
  resetData(listingName) {
    return {
      listingName,
      type: constants.RESET_DATA
    }
  },
  sortHeaders(headers, index, listingName) {
    headers[index].sorted = !headers[index].sorted
    headers = headers.map((header, i) => (index !== i) ? header.sorted = undefined : header)
    return {
      type: constants.SORTING_HEADERS,
      listingName,
      tableHeaders: headers
    }
  }
}

// Reducer
const defaultValues = {
  fetching: false,
  fetchError: null,
  data: [],
  totalElements: 0,
  searchBy: [],
  searchTerm: '',
  sortColumn: '',
  sortDirection: false,
  selectedFilterValues: [],
  appliedFilterValues: [],
  filterType: [],
  filterBy: [],
  filters: [],
  totalPages: 0,
  totalRecords: 0,
  fetchSuccess: false,
  uploadFileSuccess: null,
  fetchingInModal: false,
  deleteError: null,
  deleteSuccess: null,
  selectedQueryParams: {},
  selectedApiArgs: null,
  isFilterLoaded: true,
  isFilterFirstLoaded: true,
  prevSortObj: {},
  additionalVal: null,
  tableHeaders: []
}
export const initialState = fromJS({})

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.FETCH:
      return state
        .setIn([action.listingName, 'fetching'], true)
        .setIn([action.listingName, 'fetchError'], null)
        .setIn([action.listingName, 'totalPages'], 0)
        .setIn([action.listingName, 'totalRecords'], 0)
    case constants.FETCH_MODAL:
      return state
        .setIn([action.listingName, 'fetchingInModal'], true)
        .setIn([action.listingName, 'fetchError'], null)
    case constants.FETCH_SUCCESS:
      return state
        .set('fetching', false)
        .set('fetchSuccess', true)
    case constants.RECEIVE_LIST:
      if (action.isHandheldDevice) {
        let list = state.get('data')
        return state
          .setIn([action.listingName, 'data'], fromJS([
            ...list,
            ...action.data
          ]))
          .setIn([action.listingName, 'totalPages'], parseInt(fromJS(action.headers.get('x-pagination-count'))))
          .setIn([action.listingName, 'totalRecords'], parseInt(fromJS(action.headers.get('x-record-count'))))
          .setIn([action.listingName, 'totalElements'], fromJS(action.data.length))
          .setIn([action.listingName, 'fetching'], false)
          .setIn([action.listingName, 'tableHeaders'], action.tableHeaders)
      }
      const isAlert = (action.apiFunctionName === 'fetchAlertsAndAlarms') || (action.apiFunctionName === 'fetchMaintenanceAlerts'
        || action.apiFunctionName === 'fetchDeviceAssignments' || action.apiFunctionName === 'fetchDeviceTypeFiles' || action.apiFunctionName === 'getServiceContracts')
      const isNewsCategoryCall = action?.apiFunctionName === 'getNewsCategories'
      return state
        .setIn([action.listingName, 'data'], fromJS(isAlert ? (action.data.events || []) : isNewsCategoryCall ? (action?.data?.categories || []) : action.data))
        .setIn([action.listingName, 'isFilterLoaded'], false)
        .setIn([action.listingName, 'isFilterFirstLoaded'], false)
        .setIn([action.listingName, 'totalPages'], parseInt(fromJS(isAlert ? action?.data?.totalPages : action?.headers?.get('x-pagination-count'))))
        .setIn([action.listingName, 'totalRecords'], parseInt(fromJS(action?.headers?.get('x-record-count'))))
        .setIn([action.listingName, 'totalElements'], fromJS(action.data.length))
        .setIn([action.listingName, 'fetching'], false)
        .setIn([action.listingName, 'fetchSuccess'], true)
        .setIn([action.listingName, 'tableHeaders'], action.tableHeaders)
    case constants.FETCH_FAILURE:
      return state
        .set('fetching', false)
        .setIn([action.listingName, 'fetchSuccess'], false)
        .setIn([action.listingName, 'fetchError'], action.error)
        .setIn([action.listingName, 'fetching'], false)
        .setIn([action.listingName, 'deleteError'], null)
        .setIn([action.listingName, 'deleteSuccess'], null)
        .setIn([action.listingName, 'tableHeaders'], action.tableHeaders)
    case constants.ADD_ITEM_FAILURE:
      return state
        .setIn([action.listingName, 'fetchingInModal'], false)
        .setIn([action.listingName, 'fetching'], false)
        .setIn([action.listingName, 'fetchError'], action.error)
    case constants.ADD_ITEM_SUCCESS:
      return state
        .setIn([action.listingName, 'fetchingInModal'], false)
        .setIn([action.listingName, 'totalElements'], action.totalEle)
        .setIn([action.listingName, 'uploadFileSuccess'], fromJS(action.status))
        .updateIn([action.listingName, 'data'], data => data.unshift(fromJS(action.item)))
    case constants.RESET_ADD_ITEM_SUCCESS:
      return state
        .setIn([action.listingName, 'uploadFileSuccess'], null)
    case constants.RESET_DELETE_ITEM_SUCCESS:
      return state
        .setIn([action.listingName, 'deleteError'], null)
        .setIn([action.listingName, 'deleteSuccess'], null)
        .setIn([action.listingName, 'additionalVal'], null)
    case constants.DELETE_ITEM_SUCCESS:
      return state
        .setIn([action.listingName, 'fetchingInModal'], false)
        .updateIn([action.listingName, 'data'], data => data.filter(item => item.get('documentId') !== action.documentId))
        .setIn([action.listingName, 'totalElements'], action.totalEle)
        .setIn([action.listingName, 'deleteSuccess'], { status: action.status, name: action.docName })
        .setIn([action.listingName, 'additionalVal'], action.additionalVal)
    case constants.DELETE_ITEM_FAILURE:
      return state
        .setIn([action.listingName, 'fetchingInModal'], false)
        .setIn([action.listingName, 'deleteError'], action.error)
        .setIn([action.listingName, 'deleteSuccess'], null)
    case constants.SET_SEARCH_BY:
      return toggleSearchBy(state, action.searchBy, action.listingName)
    case constants.RESET_LISTING:
      return state
        .setIn([action.listingName, 'fetchError'], null)
        .setIn([action.listingName, 'searchBy'], List())
        .setIn([action.listingName, 'selectedQueryParams'], fromJS({}))
        .setIn([action.listingName, 'isFilterLoaded'], true)
    case constants.RESET_DATA:
      return state
        .setIn([action.listingName, 'data'], List())
        .setIn([action.listingName, 'totalPages'], 0)
        .setIn([action.listingName, 'totalRecords'], 0)
        .setIn([action.listingName, 'totalElements'], 0)
    case constants.SELECTED_API_ARGS:
      return state
        .setIn([action.listingName, 'selectedApiArgs'], action.args)
    case constants.SET_SEARCH_TERM:
      return state
        .setIn([action.listingName, 'searchTerm'], action.searchTerm)
    case constants.ADD_FILTER_CATEGORY:
      return state
        .set('filterType', action.filterType)
    case constants.ADD_FILTER_CATEGORY_VALUES:
      return state
        .updateIn(['filterBy'], (state) => {
          return state.find(category => category === action.categoryValue)
            ? state.filter(item => item !== action.categoryValue) : state.push(action.categoryValue)
        })
    case constants.APPLY_FILTERS:
      return applyFilterValues(state, action.listingName)
    case constants.SELECTED_FILTER:
      return toggleFilterValues(state, action.selections, action.listingName)
    case constants.SELECTED_QUERY_PARAM:
      return toggleQueryParams(state, action.selections, action.listingName)
    case constants.ADD_FILTERS:
      return state
        .setIn([action.listingName, 'filters'], fromJS(action.filters))
    case constants.SORT:
      return state
        .setIn([action.listingName, 'sortColumn'], fromJS(action.sortColumn))
        .setIn([action.listingName, 'sortDirection'], !state.getIn([action.listingName, 'sortDirection']))
        .setIn([action.listingName, 'prevSortObj'], fromJS({
          [action.listingName]: {
            sort: {
              'sortColumn': fromJS(action.sortColumn),
              'sortDirection': !state.getIn([action.listingName, 'sortDirection'])
            }
          }
        }))
    case constants.REDUCE_TOTAL_PAGE:
      return state
        .setIn([action.listingName, 'totalPages'], action.totalPages - 1)
    case constants.ADD_TOTAL_PAGE:
      return state
        .setIn([action.listingName, 'totalPages'], action.totalPages + 1)
    case constants.RESET_SORT:
      return state
        .setIn([action.listingName, 'sortColumn'], initialState.get('sortColumn'))
        .setIn([action.listingName, 'filterBy'], fromJS([]))
        .setIn([action.listingName, 'selectedFilterValues'], List())
        .setIn([action.listingName, 'appliedFilterValues'], List())
        .setIn([action.listingName, 'data'], List())
        .setIn([action.listingName, 'filters'], List())
    case constants.SET_DEFAULT_VALUES:
      return setInitialValues(state, action.data, action.listingName)
    case constants.RESET_FETCH_LIST_SUCCESS:
      return state
        .setIn([action.listingName, 'fetchSuccess'], false)
    case constants.FETCH_DEVICE_SOFTWARE_UPDATE_SUCCESS:
      let mapData = state.get('deviceSoftwareUpdate').toJS().data && state.get('deviceSoftwareUpdate').toJS().data.length ? state.get('deviceSoftwareUpdate').toJS().data.map((list) => {
        if (action.payload && action.payload.deviceId &&
          action.payload.deviceId.length &&
          action.payload.deviceId[0] === list.deviceId) {
          const latestStatus = action.updatedStatus
          const updatedStatus =
            latestStatus &&
            latestStatus.length &&
            latestStatus.find(
              (item) => list && item && item.deviceId === list.deviceId
            )
          let updateSoftwareStatus = updatedStatus
          return {
            ...list,
            // updated status Code is same as the cancel time status Code
            connectivity: updateSoftwareStatus && updateSoftwareStatus.connectivity,
            disableCancel: updateSoftwareStatus.swStatusCode === action.payload.swStatusCode ? action.payload.swStatusCode : null,
            concensual: updateSoftwareStatus && updateSoftwareStatus.concensual,
            deviceId: updateSoftwareStatus && updateSoftwareStatus.deviceId,
            enabled: updateSoftwareStatus && updateSoftwareStatus.enabled,
            swStatus: updateSoftwareStatus && updateSoftwareStatus.swStatus,
            swStatusCode: updateSoftwareStatus && updateSoftwareStatus.swStatusCode,
            swUpdateValue: updateSoftwareStatus && updateSoftwareStatus.swUpdateValue,
            swVersion: updateSoftwareStatus && updateSoftwareStatus.swVersion
          }

        } else {
          const latestStatus = action.updatedStatus
          const updatedStatus =
            latestStatus &&
            latestStatus.length &&
            latestStatus.find(
              (item) => list && item && item.deviceId === list.deviceId
            )
          let updateSoftwareStatus = updatedStatus
          return {
            ...list,
            connectivity: updateSoftwareStatus && updateSoftwareStatus.connectivity,
            disableCancel: list.disableCancel && updateSoftwareStatus.swStatusCode === list.disableCancel ? list.disableCancel : null,
            concensual: updateSoftwareStatus && updateSoftwareStatus.concensual,
            deviceId: updateSoftwareStatus && updateSoftwareStatus.deviceId,
            enabled: updateSoftwareStatus && updateSoftwareStatus.enabled,
            swStatus: updateSoftwareStatus && updateSoftwareStatus.swStatus,
            swStatusCode: updateSoftwareStatus && updateSoftwareStatus.swStatusCode,
            swUpdateValue: updateSoftwareStatus && updateSoftwareStatus.swUpdateValue,
            swVersion: updateSoftwareStatus && updateSoftwareStatus.swVersion
          }
        }
      }) : []
      return state
        .setIn(['deviceSoftwareUpdate', 'data'], fromJS(mapData))
    default:
      return state
  }
}

// Selectors
const getState = (state, listingName) => state.genericListing.get(listingName)

export const selectors = {
  genericListing: createSelector(getState, (state) =>
    state
  ),
  fetching: createSelector(getState, (state) =>
    state.get('fetching')
  ),
  fetchError: createSelector(getState, (state) =>
    state.get('fetchError')
  ),
  listingData: createSelector(getState, (state) =>
    state.get('data')
  ),
  totalPages: createSelector(getState, (state) =>
    state.get('totalPages') || 0
  ),
  totalRecords: createSelector(getState, (state) =>
    state.get('totalRecords') || 0
  ),
  totalElements: createSelector(getState, (state) =>
    state.get('totalElements') || 0
  ),
  searchBy: createSelector(getState, (state) =>
    state.get('searchBy')
  ),
  searchTerm: createSelector(getState, (state) =>
    state.get('searchTerm')
  ),
  sortColumn: createSelector(getState, (state) =>
    state.get('sortColumn')
  ),
  sortDirection: createSelector(getState, (state) =>
    state.get('sortDirection')
  ),
  tableHeaders: createSelector(getState, (state) =>
    state?.get('tableHeaders')
  ),
  filterBy: createSelector(getState, (state) =>
    state.get('filterBy') || []
  ),
  filterType: createSelector(getState, (state) =>
    state.get('filterType')
  ),
  selectedFilterValues: createSelector(getState, (state) =>
    state.get('selectedFilterValues')
  ),
  appliedFilterValues: createSelector(getState, (state) =>
    state.get('appliedFilterValues')
  ),
  filters: createSelector(getState, (state) =>
    state.get('filters') || []
  ),
  getRoles: createSelector(getState, (state) =>
    state.get('roles')
  ),
  uploadFileSuccess: createSelector(getState, (state) =>
    state.get('uploadFileSuccess')
  ),
  deleteError: createSelector(getState, (state) =>
    state.get('deleteError')
  ),
  deleteSuccess: createSelector(getState, (state) =>
    state.get('deleteSuccess')
  ),
  selectedQueryParams: createSelector(getState, (state) =>
    state.get('selectedQueryParams').toJS()
  ),
  selectedApiArgs: createSelector(getState, (state) =>
    state.get('selectedApiArgs')
  ),
  fetchingInModal: createSelector(getState, (state) =>
    state.get('fetchingInModal')
  ),
  fetchSuccess: createSelector(getState, (state) =>
    state.get('fetchSuccess')
  ),
  isFilterLoaded: createSelector(getState, (state) =>
    state.get('isFilterLoaded'),
  ),
  isFilterFirstLoaded: createSelector(getState, (state) =>
    state.get('isFilterFirstLoaded'),
  ),
  prevSortObj: createSelector(getState, (state) =>
    state.get('prevSortObj')
  ),
  additionalVal: createSelector(getState, (state) =>
    state.get('additionalVal')
  )
}

// helper functions

/**
  setInitialValues

  (*) update intial values
  (*) used inside reducer.
  (*) Set initialstate

  @param {map} state
  @param {object} data
  @param {string} listingName
  @return {map}
*/
const setInitialValues = (state, data = {}, listingName) => {
  const defaults = {
    'defaultSortDirection': 'sortDirection',
    'defaultSort': 'sortColumn'
  }
  let newState = state

  // default values are set in two ways
  // by using container and hoc. if state already defined no need to
  // define again
  if (!newState.has(listingName)) {
    newState = newState.set(listingName, fromJS(defaultValues))
  }

  // seting defaults is ignored for now.
  if (!dataUtils.isEmptyObject(data)) {
    Object
      .keys(defaults)
      .forEach((property) => {
        if (property in data) {
          newState = newState.setIn([listingName, defaults[property]], data[property])
        }
      })
  }

  return newState
}

/**
  getPageDetails

  (*) used to get currentPage
  (*) used in action.

  @param {map} state
  @param {string} paginationName
  @return {object}
*/
const getPageDetails = (state, paginationName) => {
  if (dataUtils.isString(paginationName)) {
    return {
      page: pageSelectors.pagination(state).getIn([paginationName, 'currentPage']) || 1,
      pageLimit: pageSelectors.pagination(state).getIn([paginationName, 'perPage']) || PAGE_LIMIT
    }
  }

  return {}
}

/**
  method is used to update filter values.
  (i) remove if filter value exist
  (ii) insert if filter value not exist

  @param {map} state
  @param {string|array} selections
  @param {string} listingName
  @return {map}
*/
const applyFilterValues = (state, listingName) => {
  let filters = state.getIn([listingName, 'selectedFilterValues'])
  return state.setIn([listingName, 'appliedFilterValues'], filters)
}
const toggleFilterValues = (state, selections, listingName) => {
  const updateValues = dataUtils.isString(selections)
    ? [selections]
    : selections

  let filters = state.getIn([listingName, 'selectedFilterValues'])

  updateValues.forEach((value) => {
    const indexOfValue = filters.indexOf(value)

    filters = (indexOfValue > -1)
      ? filters.delete(indexOfValue)
      : filters.push(value)
  })
  return state.setIn([listingName, 'selectedFilterValues'], filters)
}

/**
  @param {map} state
  @param {array} selections
  @param {string} listingName
*/
const toggleSearchBy = (state, selections, listingName) => {
  let searchBy = state.getIn([listingName, 'searchBy'])

  selections.forEach((value) => {
    const indexOfValue = searchBy.indexOf(value)

    searchBy = (indexOfValue > -1)
      ? searchBy.delete(indexOfValue)
      : searchBy.push(value)
  })

  return state.setIn([listingName, 'searchBy'], searchBy)
}

const toggleQueryParams = (state, selections, listingName) => {
  let selectedQueryParams = state.getIn([listingName, 'selectedQueryParams'])
  Object.keys(selections).forEach(name => {
    if (selections[name]) {
      selectedQueryParams = selectedQueryParams.set(name, selections[name])
    }
  })
  return state
    .setIn([listingName, 'isFilterLoaded'], true)
    .setIn([listingName, 'selectedQueryParams'], selectedQueryParams)
}

/**
  getSearchTerm

  creates value for query param searchBy
  format `${searchBy}.${searchTerm}`

  @param {map} state
  @param {string} listingName
  @return {array}
*/
function getSearchTerm(state, listingName) {
  const searchTerm = selectors.searchTerm(state, listingName)
  const searchBy = selectors.searchBy(state, listingName).toJS()
  if (dataUtils.isString(searchTerm) && searchTerm.trim()) {
    if (dataUtils.isEmptyArray(searchBy)) {
      return [searchTerm]
    } else {
      return searchBy.map(({ header }) => {
        if (header)
          return !header.trim()
            ? searchTerm
            : `${header}.${searchTerm}`
      })
    }
  }

  return []
}
