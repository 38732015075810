import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { ErrorMessage } from 'formik'
import 'antd/lib/select/style/css'
import './Select.scss'

const propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  isRequired: PropTypes.boo,
  setFieldValue: PropTypes.func,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isAync: PropTypes.bool,
  handleOnChange: PropTypes.func,
  isCallback: PropTypes.bool,
  callbackFunction: PropTypes.func,
  isForm: PropTypes.bool,
  values: PropTypes.object
}

const antSelect = ({
  options,
  label,
  defaultValue,
  className,
  style,
  isRequired,
  name,
  setFieldValue,
  placeholder,
  isAync,
  handleOnChange,
  isCallback,
  callbackFunction,
  isForm,
  values,
  ...restProps
}) => (
  <div
    className={`input-div ${className ? className : ''}`}
    style={{ ...style }}
  >
    <label className='select-label-name'>
      {label} {isRequired && <span className='required'>*</span>}
    </label>
    {isAync ? (
      <Select
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleOnChange}
        {...restProps}
      >
        {options}
      </Select>
    ) : isCallback ? (
      <Select
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={(value, type) => {
          callbackFunction(value, type, setFieldValue, values)
          setFieldValue(name, value)
        }}
        {...restProps}
      >
        {options}
      </Select>
    ) : (
      <Select
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={(value) => setFieldValue(name, value)}
        {...restProps}
      >
        {options}
      </Select>
    )}
    <span className='error-message'>
    {isForm && <ErrorMessage name={name} /> }
    </span>
  </div>
)
antSelect.defaultProps = {
  isForm: true
}
antSelect.propTypes = propTypes

export default antSelect
