import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import star from 'static/icons/star.svg'
import defaultUserProfileImage from 'static/icons/user-profile.svg'
import style from './Header.scss'
import { Menu, Dropdown } from 'antd'
import 'antd/dist/antd.css'
import LogoutSvg from 'static/icons/LogoutSvg'
import UserProfileSettingSvg from 'static/icons/UserProfileSettingSvg'
import { FormattedMessage } from 'react-intl'
import NewsFeedCarouselContainer from 'containers/NewsFeedCarouselContainer/NewsFeedCarouselContainer'
import { Link } from 'react-router-dom'
import { PATHS } from 'routes'
import { isArray } from 'utils'
import { Rate, Card } from 'antd'
import { Icon } from '@liquid-design/liquid-design-react'
import RatingPopupContainer from 'containers/RatingPopupContainer/RatingPopupContainer'
import Tooltip from 'components/Tooltip/Tooltip'
import classNames from 'classnames/bind'
import { deviceMaxWidth } from 'utils/screenSizeConstants'

const cx = classNames.bind(style)

class Header extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    newsFeedCategory: PropTypes.array,
    openModal: PropTypes.func,
    permission: PropTypes.object,
    userRating: PropTypes.object,
    isRated: PropTypes.bool,
    history: PropTypes.object,
    version: PropTypes.string,
    handleClickRating: PropTypes.func,
    openConsentStatus: PropTypes.bool,
    toggle: PropTypes.func,
    userProfile: PropTypes.object
  }

  componentDidMount() {
    const {
      user,
      userProfile
    } = this.props
    window.gtag('set', 'user_properties', {
      org_type: user?.toJS()?.orgType,
      org_name: user?.toJS()?.orgName,
      user_role: user?.toJS()?.role,
      user_location: userProfile?.country?.countryName,
      application_domain: userProfile?.businessDomain === null ? 'Not Available' : userProfile?.businessDomain?.domainName
    })
  }

  handleOpenNewsFeed = (category) => {
    const { openModal } = this.props
    const options = {
      width: window.innerWidth <= deviceMaxWidth.MOBILE ? '' : '67%',
      title: <FormattedMessage id='next.LATEST_NEWS' />,
      wrapClassName: 'news-feed-carousel'
    }
    openModal(
      <NewsFeedCarouselContainer
        category={category}
      />,
      options
    )
  }
  handleClickRating = () => {
    const { history, permission, userRating, user, openModal, ...restProps } = this.props
    if (permission && permission['feedback'] && permission['feedback']['VIEW']) {
      history.push(PATHS.RATING_IMPROVEMENT)
    } else
    // if (!isRated || (userRating && userRating.status))
    {
      openModal(<RatingPopupContainer user={user?.toJS()} userRating={userRating} {...restProps} />,
        {
          title: <FormattedMessage id='next.USER_RATING_TITLE' />
        })
    }
  }
  newsFeedComponent = () => {
    const { newsFeedCategory } = this.props
    return (
      <div className={cx('marquee')}>
        <div className={cx('marquee-item')}>
          {(isArray(newsFeedCategory) && newsFeedCategory?.length > 0) ?
            newsFeedCategory.map((category, key) => (
              <span
                style={{ cursor: 'pointer' }}
                key={category.templateId}
                onClick={() => this.handleOpenNewsFeed(category)}
                className='read_marquee'
              >
                {key !== newsFeedCategory.length - 1
                  ? `${category.templateName} | `
                  : category.templateName}
              </span>
            ))
            : <FormattedMessage id='next.NEWS_FEED_CATEGORY_EMPTY_DATA' />}
        </div>
      </div>
    )
  }
  render() {
    const {
      user,
      userRating,
      permission,
      isRated,
      version,
      toggle,
      userProfile
    } = this.props
    const userInfo = user?.toJS()
    const { invitedVia, validated } = user && user.toJS() || ''
    let isNewUser = false
    if (invitedVia !== null && invitedVia === 'Self Registration' && validated === false) {
      isNewUser = true
    }
    const content = (
      <Menu>
        <Menu.Item key='0'>
          {isRated ? (
            <Card className={cx('rating_summary')}>
              <div>
                <h2 className={cx('average_rating')}>
                  {userRating &&
                    (userRating.avgRating || userRating.rating) > 0 &&
                    (userRating.avgRating || userRating.rating).toFixed(1)}
                </h2>
              </div>
              <div className={cx('user_feedback__rate')}>
                {permission &&
                  permission['feedback'] &&
                  permission['feedback']['VIEW'] ? (
                  <Rate
                    style={{ fontSize: '18px', marginLeft: '4px' }}
                    value={userRating && userRating.avgRating}
                    disabled
                  />
                ) : (
                  <Rate
                    className={cx('rating_by_user')}
                    value={userRating && userRating.rating}
                    disabled
                  />
                )}
                {permission &&
                  permission['feedback'] &&
                  permission['feedback']['VIEW'] && (
                    <div className={cx('feedback-ratings')}>
                      <Icon size={20} name='person' isFilled />
                      <span style={{ fontSize: '13px', marginLeft: '6px' }}>
                        {userRating && userRating.noOfUserFeedBacks}{' '}
                        <FormattedMessage id='next.MAIN_HEADER_TOTAL_FEEDBACK' />
                      </span>
                    </div>
                  )}
              </div>
            </Card>
          ) : (
            <div className={cx('no-feeback')}>
              {permission &&
                permission['feedback'] &&
                permission['feedback']['VIEW'] ? (
                <FormattedMessage id='next.USER_FEEDBACK_ZERO_FEEDBACK' />
              ) : (
                <FormattedMessage id='next.USER_FEED_BACK_NOT_FOUND' />
              )}
            </div>
          )}
        </Menu.Item>
      </Menu>
    )
    const menu = (
      <Menu>
        <Menu.Item>
          <Link to={PATHS.USER_PROFILE}>
            <div className={cx('dropdown-manage-profile')}>
              <div style={{ flex: 1 }}>
                <UserProfileSettingSvg width='25' height='25' />
              </div>
              <div style={{ marginRight: '28%' }}>
                <span>
                  <FormattedMessage id='next.MAIN_HEADER_MANAGE_PROFILE' />
                </span>
              </div>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={PATHS.LOGOUT}>
            <div className={cx('dropdown-manage-profile')}>
              <div style={{ flex: 1 }}>
                <LogoutSvg width='25' height='25' />
              </div>
              <div style={{ marginRight: '50%' }}>
                <span>
                  <FormattedMessage id='next.MAIN_MENU_LOGOUT_BUTTON' />
                </span>
              </div>
            </div>
          </Link>
        </Menu.Item>
        <hr className={cx('hr-break')} />
        <Menu.Item>
          <div className={cx('trademarks')}>
            <div className={cx('trademarks-flex')}>
            <h3>
              <FormattedMessage id='next.MYMILLIQ_HEADING' />
              <sup className={cx('trade-mark')}>
                <FormattedMessage id='next.TRADEMARK' />
              </sup>
            </h3>
            <span>
              <FormattedMessage id='next.MYMILLIQ_VERSION' />
              <span>{` ${version || '9.5.2'} `}</span>
            </span>
            </div>
          </div>
          <div style={{ marginTop: '5px' }}>
            <span>
              <FormattedMessage id='next.COPY_RIGHT' />
              <sup className={cx('sub-heading-tm')}>
                <FormattedMessage id='next.TRADEMARK' />
              </sup>
              <br />
              <FormattedMessage id='next.ALL_RIGHT_RESERVED' />
            </span>
          </div>
        </Menu.Item>
      </Menu>
    )
    return (
      <>
        <div className={cx('header')}>
          <ul className={cx('navigation-bar')}>
            <li>
              <div className="Menu_Icon" onClick={toggle}>
                <svg viewBox="0 0 24 24"
                  className="icon icon-<path d=&quot;M2,19 L22,19 L22,16.6666667 L2,16.6666667 L2,19 L2,19 Z M2,13.1666667 L22,13.1666667 L22,10.8333333 L2,10.8333333 L2,13.1666667 L2,13.1666667 Z M2,5 L2,7.33333333 L22,7.33333333 L22,5 L2,5 L2,5 Z&quot;></path> has-action"
                  style={{ height: '24px', width: '24px' }}>
                  <g viewBox="0 0 24 24">
                    <path d="M2,19 L22,19 L22,16.6666667 L2,16.6666667 L2,19 L2,19 Z M2,13.1666667 L22,13.1666667 L22,10.8333333 L2,10.8333333 L2,13.1666667 L2,13.1666667 Z M2,5 L2,7.33333333 L22,7.33333333 L22,5 L2,5 L2,5 Z" />
                  </g>
                </svg>
              </div>
            </li>
            <li className={cx('milliq-trademark')}>
              <h4 className={cx('milliq-text-h4')}>
                <FormattedMessage id='next.MYMILLIQ_HEADING' />
                <sup className={cx('trademark-text')}>TM</sup>
              </h4>
            </li>
            {
              isNewUser ? (
                <div className={cx('news-marquee')} />
              )
                :
                (
                  <>
                    <li className={cx('news-marquee')}>
                      {this.newsFeedComponent()}
                    </li>
                    {permission && permission['ratingfeedback'] && permission['ratingfeedback']['VIEW'] ?
                      <li className={cx('rating')} onClick={this.handleClickRating}>
                        <Dropdown
                          overlay={content}
                          placement='bottomCenter'
                          trigger={['click', 'hover']}
                          overlayClassName='star_rating_dropdown'
                          arrow
                        >
                          <img alt='true' className={cx('star-icon')} src={star} />
                        </Dropdown>
                      </li> : <span style={{ marginLeft: '10px' }} />
                    }
                  </>
                )
            }
            <li className={cx('rating-div')} style={{ fontSize: '1.5rem', maxWidth: '8px' }}>
              <span>|</span>
            </li>
            <li className={cx('user-profile')}>
              <div className={cx('user-icon-div')}>
                <img alt='true' className={cx('user-profile-icon')} src={userProfile?.userImage ? `data:image/jpeg;base64,${userProfile.userImage}` : defaultUserProfileImage} />
              </div>
              <div className={cx('user-profile-headername')} style={{ fontSize: '0.8rem' }}>
                <span>
                  <FormattedMessage id='next.GREETING' />,
                </span>{' '}
                <br />
                <span className={cx('user-name-header')}>
                  <Tooltip placement='bottom' title={userInfo?.username.toUpperCase()} tooltipStyle={{ maxWidth: '8rem' }} />
                </span>
              </div>
            </li>
            <li style={{ flex: 1, maxWidth: '24px' }}>
              <Dropdown placement='bottomLeft' overlay={menu}>
                <a className={cx('ant-dropdown-link')}>
                  <div className={cx('cabab-menu')} />
                </a>
              </Dropdown>
            </li>
          </ul>
        </div>
        <div className={cx('news-marquee _marquee')}>
          {this.newsFeedComponent()}
        </div>
      </>
    )
  }
}

export default React.memo(Header)
