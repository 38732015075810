import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@liquid-design/liquid-design-react'
import Accordion from './Accordion'
import SearchFilter from 'components/GenericFilters/SearchFilter'
import DateRangeFilter from 'components/GenericFilters/DateRangeFilter'
import DeviceAssignmentSearch from 'components//GenericFilters/DeviceAssignmentSearch'
import OptimizedDeviceAssignmentSearch from 'components/GenericFilters/OptimizedDeviceAssignmentSearch'
import CheckBoxFilter from 'components/GenericFilters/CheckBoxFilter'
import { isArray } from 'utils'
import classNames from 'classnames/bind'
import styles from './FilterModal.scss'
import { filterTypes, filterLabels } from 'constants/deviceList'
import { mainRoutes } from 'constants/mainroutes'
import { moduleId } from 'containers/DeviceCertificateDashboardContainer/DeviceCertificateDashboardContainer'

const cx = classNames.bind(styles)
const propTypes = {
  applyFilter: PropTypes.func,
  categories: PropTypes.array,
  listingName: PropTypes.string,
  selectedFilterValues: PropTypes.array,
  endTime: PropTypes.string,
  startTime: PropTypes.string
}


const getLabel = (type) => {
  if (location.pathname === mainRoutes.MAIN_MENU_DEVICE_LIST)
    if (type === filterTypes.CUSTOMER_NAME)
      return filterLabels.DEVICE_CUSTOMER_NAME
  return type
}
const FilterModal = (props) => {
  const { applyFilter, categories, selectedFilterValues, listingName, endTime, startTime } = props
  let filterCategoryObj = {}
  selectedFilterValues?.length && selectedFilterValues.forEach((value) => {
    let filterCategory = listingName === moduleId && value?.split('.')[0] === 'expiry' ? 'expiry_within' : value?.split('.')[0]
    filterCategoryObj[filterCategory] = filterCategoryObj[filterCategory] ? filterCategoryObj[filterCategory] + 1 : 1
  })
  return (
    <div className={cx('modal-container')}>
      <Accordion className="accordion-menu _scroll">
        {isArray(categories) && categories.map((category, index) => {
          return (
            <div
              label={getLabel(category.categoryType)}
              key={index}
              count={(category.dateRange ? (endTime || startTime ? 1 : 0) : filterCategoryObj[category.categoryType]) || 0}
            >
              {category.searchBox && category.dateRange ? (
                <div>
                  <SearchFilter
                    category={category}
                    categoryName={category.categoryType}
                    modeOfSelect={category.combobox ? 'combobox' : 'multiple'}
                    {...props}
                  />
                  <DateRangeFilter category={category} {...props} />
                </div>
              ) : category.searchBox ? (
                <SearchFilter
                  disableSearchFilter={category.disableSearchFilter}
                  category={category}
                  categoryName={category.categoryType}
                  modeOfSelect={category.combobox ? 'combobox' : 'multiple'}
                  {...props}
                />
              ) : category.deviceAssignmentSearch ? (
                <DeviceAssignmentSearch
                  category={category}
                  modeOfSelect={category.combobox ? 'combobox' : 'multiple'}
                  {...props}
                />
              ) : category.optimizedDeviceAssignmentSearch ? (
                <OptimizedDeviceAssignmentSearch
                  category={category}
                  modeOfSelect={category.combobox ? 'combobox' : 'multiple'}
                  {...props}
                />
              ) : category.dateRange ? (
                <DateRangeFilter category={category} {...props} />
              ) : (
                <CheckBoxFilter category={category} {...props} />
              )}
            </div>
          )
        })}
      </Accordion>
      <div className={cx('apply-filter-button')}>
        <Button appearance='highlight' onClick={applyFilter}>Apply</Button>
      </div>
    </div>
  )
}
FilterModal.propTypes = propTypes
FilterModal.defaultProps = {
  categories: []
}
export default FilterModal
