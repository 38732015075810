import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { routerActions } from 'connected-react-router'
import { debounce } from 'debounce'
import * as dataUtils from 'utils/dataUtils'
import {
  actions as listingActions,
  selectors as listingSelectors
} from 'store/modules/GenericListing'
import {
  selectors as userSelectors
} from 'store/modules/User'
import GenericListing from 'components/GenericListing/GenericListing'
import {
  actions as PageActions,
  selectors as pageSelectors
} from 'store/modules/Pagination'
import { fromJS } from 'immutable'
import {
  selectors as userProfileSelectors,
} from 'store/modules/UserProfile'
import Loader from 'components/Loader/Loader'

class GenericListingContainer extends PureComponent {
  static propTypes = {
    filters: PropTypes.array,
    loadList: PropTypes.func,
    loadFilters: PropTypes.func,
    setCurrentPage: PropTypes.func,
    applySort: PropTypes.func,
    setSearchBy: PropTypes.func,
    setSearchTerm: PropTypes.func,
    addfilterCatogory: PropTypes.func,
    addfilterCatogoryValues: PropTypes.func,
    openModal: PropTypes.func,
    close: PropTypes.func,
    handlePush: PropTypes.func,
    permission: PropTypes.object,
    selectedFilterValues: PropTypes.array,
    showFilters: PropTypes.bool,
    listingRow: PropTypes.func,
    listingApi: PropTypes.string.isRequired,
    listingApiArgs: PropTypes.object,
    deleteApi: PropTypes.string,
    removeItem: PropTypes.func,
    handleSort: PropTypes.func,
    filterCategories: PropTypes.array,
    resetSort: PropTypes.func,
    dateFormat: PropTypes.string,
    loadListSort: PropTypes.func,
    resetPage: PropTypes.func,
    notificationDashboard: PropTypes.bool,
    defaultSortDirection: PropTypes.bool,
    defaultSort: PropTypes.string,
    setInitialValues: PropTypes.func,
    listingName: PropTypes.string.isRequired,
    genericListing: PropTypes.object,
    searchBy: PropTypes.array,
    resetListing: PropTypes.func,
    addApiArgs: PropTypes.func,
    fetchDataOnMount: PropTypes.bool,
    minSearchCharacter: PropTypes.number,
    setPerPageLimit: PropTypes.func,
    paginationName: PropTypes.string,
    resetPerPageLimit: PropTypes.func,
    initiatedSearch: PropTypes.func, // Optional, to listen whether searching has been initiated
    tableHeaders: PropTypes.array,
    applyFilters: PropTypes.func,
    userProfile: PropTypes.object,
    perPage: PropTypes.number
  }
  constructor(props) {
    super(props)
    const { defaultSortDirection, defaultSort, setInitialValues } = this.props
    // Insert custom values to state and set initialState to store berfore rendering
    const values = dataUtils.rectifyObject({
      defaultSortDirection,
      defaultSort
    })
    this.state={
      currentPerPage: null,
      isMounted: false
    }

    if (!dataUtils.isEmptyObject(values)) {
      setInitialValues(values)
    } else {
      setInitialValues()
    }
  }
  async componentDidMount () {
    const { setSearchBy, loadList, loadFilters, searchBy, filterCategories, listingApiArgs, addApiArgs} = this.props
    let { fetchDataOnMount,userProfile,setPerPageLimit, paginationName } = this.props
    if (dataUtils.isArray(searchBy)) {
      setSearchBy(searchBy)
    }
    if (dataUtils.isObject(listingApiArgs)) {
      addApiArgs(listingApiArgs)
    }
    const profile = userProfile?.toJS()
    if (profile) {
      await setPerPageLimit(profile?.userProfileSettings?.displayPerPage, paginationName)
      if (fetchDataOnMount) {
        loadList()
        this.setState({ isMounted: true })
      }
    }
    loadFilters(filterCategories)
  }
  componentDidUpdate = async(prevProps, prevState, snapshot) => {
    const { filterCategories: prevFilterCategories } = prevProps
    const { filterCategories: nextFilterCategories, loadFilters , userProfile , setPerPageLimit, paginationName, loadList } = this.props
    const { isMounted } = this.state
    const _prevFilterCategories = fromJS(prevFilterCategories)
    const _nextFilterCategories = fromJS(nextFilterCategories)
    if (_prevFilterCategories && !_prevFilterCategories.equals(_nextFilterCategories)) {
      loadFilters(nextFilterCategories)
    }
    if (!isMounted && userProfile && userProfile !== prevProps?.userProfile ) {
      this.setState({ isMounted: true })
      const profile = userProfile?.toJS()
      await setPerPageLimit(profile?.userProfileSettings?.displayPerPage, paginationName)
      loadList()
    }
  }
  updateSearchTerm = ((value) => {
    const { setSearchTerm } = this.props
    setSearchTerm(value)
  })
  handleCurrentPageChange = async(page) => {
    const { loadList, setCurrentPage } = this.props
    await setCurrentPage(page)
    debounce(loadList(), 230)
  }
  handlePerPageLimit = async(perPage) => {
    const { setPerPageLimit, loadList, paginationName } = this.props
    this.setState({
      currentPerPage:perPage
    })
    await setPerPageLimit(perPage, paginationName)
    debounce(loadList(), 230)
  }
  handleSearch = async (searchKey) => {
    const {
      loadList, setCurrentPage, minSearchCharacter, genericListing, initiatedSearch
    } = this.props
    const searchTerm = searchKey || genericListing.get('searchTerm')
    const hasValidSearchTerm = searchTerm.trim().length >= minSearchCharacter
    const noFiltersSelected = genericListing.get('selectedFilterValues').isEmpty()

    if (!hasValidSearchTerm && noFiltersSelected) {
      return false
    }
    initiatedSearch && initiatedSearch(searchTerm)
    await setCurrentPage(1)
    loadList()
  }
  handleFilters = async (event) => {
    const { addfilterCatogory } = this.props
    const selectedValue = event.target.value
    const selectedCategory = selectedValue && selectedValue.split('_')[0]
    // await setCurrentPage(1)
    await addfilterCatogory(selectedCategory, selectedValue)
  }
  handleApplyFilter = async () => {
    const { loadList, applyFilters } = this.props
    await applyFilters()
    await loadList()
  }
  handleSort = (header) => {
    const {loadListSort, applySort} = this.props
    applySort(header)
    loadListSort()
  }
  componentWillUnmount () {
    const { setSearchTerm, resetSort, resetListing, resetPerPageLimit, resetPage } = this.props
    resetPerPageLimit()
    setSearchTerm('')
    resetSort()
    resetListing()
    resetPage()
  }
  render () {
    const { genericListing, permission, listingName, tableHeaders, userProfile, ...restProps } = this.props
    return (
      <Fragment>
      {!userProfile && <Loader /> }
      {genericListing ? <GenericListing
        {...restProps}
        headers={tableHeaders}
        fetching={genericListing.get('fetching')}
        searchTerm={genericListing.get('searchTerm')}
        data={genericListing.get('data')}
        sortDirection={genericListing.get('sortDirection')}
        sortColumn={genericListing.get('sortColumn')}
        totalPages={genericListing.get('totalPages')}
        totalRecords={genericListing.get('totalRecords')}
        roles={genericListing.get('roles')}
        selectedQueryParams={genericListing.get('selectedQueryParams')?.toJS()}
        fetchError={genericListing.get('fetchError')}
        categories={genericListing.get('filters')?.toJS()}
        updateSearchTerm={this.updateSearchTerm}
        handleUserSearch={this.handleSearch}
        handleUserFilters={this.handleFilters}
        handleApplyFilter={this.handleApplyFilter}
        handleSort={this.handleSort}
        handleSearch={this.handleSearch}
        handleCurrentPageChange={this.handleCurrentPageChange}
        handlePerPageLimit={this.handlePerPageLimit}
        listingName={listingName}
        permission={permission && permission?.toJS()} />
      : null}
      </ Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    genericListing: listingSelectors.genericListing(state, ownProps.listingName),
    tableHeaders: listingSelectors.tableHeaders(state, ownProps.listingName),
    permission: userSelectors.getUserPrivilege(state),
    pagination: pageSelectors.pagination(state)?.toJS(),
    perPage: pageSelectors.perPage(state),
    userProfile: userProfileSelectors.getUserProfile(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    loadList: (isHandheldDevice = false, blockFetch = false) => {
      return listingActions.loadList(
        ownProps.listingApi,
        ownProps.paginationName,
        isHandheldDevice,
        ownProps.listingName,
        blockFetch,
        ownProps.headers,
        ownProps.data
      )
    },
    sortHeaders: (headers, index) => listingActions.sortHeaders(headers, index, ownProps.listingName),
    loadFilters: (filterCategories) => listingActions.loadFilters(filterCategories, ownProps.listingName),
    removeItem: (args, additionalVal) => listingActions.removeItem(ownProps.deleteApi, args, ownProps.listingName, additionalVal),
    addfilterCatogory: (selectedCategory, selectedValue) => listingActions.addfilterCatogory(selectedCategory, selectedValue, ownProps.listingName),
    refreshPage: () => listingActions.refreshPage(
      ownProps.listingApi,
      ownProps.paginationName,
      ownProps.listingName
    ),
    applyFilters: () => listingActions.applyFilter(ownProps.listingName),
    addQueryParams: (selection) => listingActions.addQueryParams(selection, ownProps.listingName),
    addApiArgs: (selection) => listingActions.addApiArgs(selection, ownProps.listingName),
    setCurrentPage: (page) => PageActions.setCurrentPage(page, ownProps.paginationName),
    setPerPageLimit: (page) => PageActions.setPerPageLimit(page, ownProps.paginationName),
    resetPerPageLimit: () => PageActions.resetPerPageLimit,
    toggleActivation: () => listingActions.toggleActivation(ownProps.listingName),
    applySort: (header) => listingActions.applySort(header, ownProps.listingName),
    setSearchTerm: (searchKey) => listingActions.setSearchTerm(searchKey, ownProps.listingName),
    handlePush: () => routerActions.push(),
    resetSort: () => listingActions.resetSort(ownProps.listingName),
    loadListSort: () => listingActions.loadList(
      ownProps.listingApi,
      ownProps.paginationName,
      null,
      ownProps.listingName,
      null,
      ownProps.headers
    ),
    resetPage: () => PageActions.resetPage(ownProps.paginationName),
    setInitialValues: (values) => listingActions.setInitialValues(values, ownProps.listingName),
    setSearchBy: (value) => listingActions.setSearchBy(value, ownProps.listingName),
    resetListing: () => listingActions.resetListing(ownProps.listingName)
  }, dispatch)
}

GenericListingContainer.defaultProps = {
  listingName: 'default',
  fetchDataOnMount: true,
  minSearchCharacter: 0
}

export default connect(mapStateToProps, mapDispatchToProps)(GenericListingContainer)
