import React, { useState } from 'react'
import PropTypes from 'prop-types'
import style from './GenericHeader.scss'
import classNames from 'classnames/bind'
import { Row, Col } from 'antd'
import { Icon, SearchBar, Modal } from '@liquid-design/liquid-design-react'
import FIlterModalContainer from 'containers/FIlterModalContainer/FIlterModalContainer'
import { FormattedMessage, injectIntl } from 'react-intl'
import { deviceMaxWidth } from 'utils/screenSizeConstants'
import { roles } from 'constants/profile'

const cx = classNames.bind(style)

const propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  handleSubmit: PropTypes.func,
  showSearch: PropTypes.bool,
  showFilter: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleApplyFilter: PropTypes.func,
  showFilters: PropTypes.bool,
  additionalElement: PropTypes.element,
  updateSearchTerm: PropTypes.func,
  defaultSearchTerm: PropTypes.string,
  intl: PropTypes.object,
  isCompactView: PropTypes.bool,
  listingName: PropTypes.string
}

const GenericHeader = (props) => {
  const {
    title,
    handleSearch,
    placeholder,
    showSearch,
    handleApplyFilter,
    showFilters,
    additionalElement,
    defaultSearchTerm,
    updateSearchTerm,
    intl,
    isCompactView,
    listingName
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [search, openSearch] = useState(false)
  const handleFilter = () => {
    setIsOpen(false)
    handleApplyFilter()
  }
  const customStyles = {
    content: {
      width: '400px',
      maxHeight: '400px',
      minHeight: '200px',
      top: '17%',
      left: '48.3%',
      right: 'auto',
      bottom: 'auto',
      padding: 0,
    },
  }
  const onChangeBlur = () => {
    defaultSearchTerm === '' && openSearch(false)
  }

  const updateSearch = async (value) => {
    await updateSearchTerm(value)
    await handleSearch(value)
  }

  const searchComponent = () => {
    return search ?
      <SearchBar
        className={'search-bar'}
        autoFocus
        onBlur={() => onChangeBlur()}
        onSubmit={(value) => updateSearch(value)}
        value={defaultSearchTerm}
        placeholder={placeholder && intl.formatMessage({ id: placeholder }) || 'Search'}
      />
      :
      <Icon
        className={cx('search-bar-div')}
        name='search'
        onClick={() => openSearch(true)}
        size={18}
      />
  }
  return (
    <>
      <div className={cx(`${search ? 'header_bg' : 'header_bg search_icon_gray'}`)}>
        <span className={cx('header_title')}>
          {typeof title !== 'object' && title && <FormattedMessage id={`next.${title}`} />}
          {typeof title === 'object' && title}
        </span>
        {window.innerWidth > deviceMaxWidth.MOBILE ?
          <>{showSearch && searchComponent()}
            {showFilters && (
              <Icon
                onClick={() => setIsOpen(true)}
                className={cx('search-bar-icon', { 'filter-open': isOpen })}
                name='filter'
                size={18}
              />
            )}
          </> : <></>}
        {window.innerWidth <= deviceMaxWidth.IPAD ?
          <div className={cx({ 'mainsideContainer': isOpen })}>
            <div id="mySidenav" className={cx('sidenav', { 'rightSidenav': !isOpen })}>
              <div className={cx('sc-lmoMRL hscMjT')}>
                <div><h2><b>Filter by</b></h2></div>
                <div className="sc-gsTCUz eQrQZm LD__Icon" onClick={() => setIsOpen(false)}>
                  <svg width="20px" height="20px" fill="#2DBECD" viewBox="0 0 20 20"><path d="M15.657 2.929a1 1 0 1 1 1.414 1.414L4.343 17.071a1 1 0 0 1-1.414-1.414L15.657 2.929z"></path><path d="M17.071 15.657a1 1 0 1 1-1.414 1.414L2.929 4.343A1 1 0 0 1 4.343 2.93l12.728 12.728z"></path></svg>
                </div>
              </div>
              <div className={cx('emptyDiv')}>
                <div className="sc-iJuUWI OYaxE">
                  <FIlterModalContainer {...props} applyFilter={() => handleFilter()} />
                </div>
              </div>
            </div>
          </div> :
          <Modal
            id="filter-modal"
            isOpen={isOpen}
            label={
              <h2>
                <b>Filter by</b>
              </h2>
            }
            open={isOpen}
            reactModalProps={{
              style: customStyles,
            }}
            overlayAuxClassName={cx('modal-class', { 'compact-view-filter': isCompactView && listingName === roles.CUSTOMER_MAINTENANCE })}
            onClose={() => setIsOpen(false)}
          >
            <FIlterModalContainer {...props} applyFilter={() => handleFilter()} />
          </Modal>}
        {additionalElement && additionalElement}
      </div>
      {window.innerWidth <= deviceMaxWidth.MOBILE && <Row>
        <Col xs={21}>{showSearch && <div>{searchComponent()}</div>}</Col>
        {showFilters && (
          <Col xs={3}>
            <Icon
              onClick={() => setIsOpen(true)}
              className={cx('search-bar-icon', { 'filter-open': isOpen })}
              name='filter'
              size={18}
            />
          </Col>
        )}
      </Row>}
    </>
  )
}

GenericHeader.propTypes = propTypes
GenericHeader.defaultProps = {
  placeholder: 'Search'
}

export default injectIntl(GenericHeader)
